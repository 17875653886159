import React from "react";
import {PanelDefault} from "../PanelDefault";
import {BootstrapLayout} from "../BootstrapLayout";
import logo from "../../static/imgs/uniteller_logo.png"
import styles from  "./Offer.module.scss"
import {Link} from "react-router-dom";

//
// document.adoptedStyleSheets = [...document.adoptedStyleSheets, css]
// console.log("test")
// console.log(document.adoptedStyleSheets.flat())
export const Offer = () => {
    // document.bootstrapcss.disabled = true;
    return (
        <BootstrapLayout><PanelDefault>
            <Link to="/"><h4>Назад</h4></Link>
            <h4><b>Публичная оферта №1 о заключении договора пожертвования от 01.01.2014</b></h4>
            <p>1. Настоящая публичная оферта, именуемая в дальнейшем «Оферта», является предложением Централизованной
                религиозной организации Российская Ассоциация Буддистов Алмазного Пути Традиции Карма Кагью, именуемая в
                дальнейшем «Организация», реквизиты которой указаны в разделе 14 Оферты, в лице Президента Койбагарова
                А.Ш.,
                действующего на основании Устава, заключить с любым, кто отзовется на Оферту, договор пожертвования,
                именуемый в дальнейшем «Договор», на условиях, предусмотренных Офертой.</p>
            <p>2. Оферта является публичной офертой в соответствии с пунктом 2 статьи 437 Гражданского кодекса
                Российской
                Федерации.</p>
            <p>3. Оферта вступает в силу со дня, следующего за днем размещения ее на Сайте в сети Интернет по адресу:
                www.buddhism.ru.</p>
            <p>4. Оферта действует бессрочно. Организация вправе отменить Оферту в любое время без объяснения
                причин.</p>
            <p>5. В Оферту могут быть внесены изменения и дополнения, которые вступают в силусо дня, следующего за днем
                их
                размещения на Сайте, указанном в п. 3 Оферты.</p>
            <p>6. Недействительность одного или нескольких условий Оферты не влечет недействительности всех остальных
                условий Оферты.</p>
            <p>7. Местом размещения Оферты считается город Санкт-Петербург, Российская Федерация.</p>
            <p>8. Существенные условия Договора</p>
            <p>8.1. Сумма пожертвования: сумма пожертвования определяется Жертвователем.</p>
            <p>8.2. Назначение пожертвования: добровольное пожертвование на уставную деятельность.</p>
            <p>9. Порядок заключения Договора.</p>
            <p>9.1. Договор заключается путем акцепта Оферты Жертвователем.</p>
            <p>9.2. Оферта может быть акцептована Жертвователем любым из следующих способов:- путем перечисления
                Жертвователем денежных средств в пользу Организации платежным поручением по реквизитам, указанным в
                разделе
                14 Оферты, с указанием «пожертвование на уставную деятельность» в строке: «назначение платежа», - путем
                перечисления Жертвователем денежных средств с использованием пластиковых - путем внесения денежных
                средств в
                кассу Организации.</p>
            <p>10. Совершение Донором любого из действий, предусмотренных пунктом 9.2 Оферты, считается акцептом Оферты
                в
                соответствии с частью 3 статьи 438 Гражданского кодекса Российской Федерации.</p>
            <p>11. Датой акцепта Оферты и, соответственно, датой заключения Договора является дата поступления денежных
                средств от Жертвователя на расчетный счет Организации, указанный в п. 14 Оферты.</p>
            <p>12. Прочие условия: Совершая действия, предусмотренные данной Офертой, Жертвователь подтверждает, что
                ознакомлен с условиями и текстом настоящей Оферты, целями деятельности Организации, осознает значение
                своих
                действий, имеет полное право на их совершение и полностью принимает условия настоящей Оферты.</p>
            <p>13. Настоящая Оферта регулируется и толкуется в соответствии с законодательством Российской
                Федерации.</p>
            <p>14. Реквизиты Организации: Централизованная религиозная организация Российская Ассоциация Буддистов
                Алмазного
                Пути Традиции Карма Кагью. Место нахождения: 190068, Санкт-Петербург г, Никольский пер, 7, 26 ИНН
                7810301506/ КПП 783801001 ОГРН 1037858019495 Расчетный счет: 40703 81053 80901 11891 В ОАО "Сбербанк
                России"
                г. Москва БИК 044525225 Кор/сч 30101 81040 00000 00225</p>

            <div>
                <img src={logo} alt="uniteller_logo" className={styles.img} />
                <p className={styles.text}>Uniteller. Платите самостоятельно в виртуальном и реальном мирах! Компания Uniteller
                    – первая в России
                    процессинговая компания с полным комплексом услуг эквайринга платежей в Интернете и сфере
                    самообслуживания.
                    Платежная система Uniteller соответствует требованиям стандарта безопасности данных индустрии
                    платежных карт
                    PCI DSS, решения компании сертифицированы в соответствии с требованиями PCI PA-DSS.</p>
            </div>


        </PanelDefault></BootstrapLayout>
    );
}