import React from "react";
import styles from "./PanelDefault.module.less";
import clsx from "clsx";

export const PanelDefault = ({children}) => {
    return (
        <div className={clsx("panel panel-default", styles.panelLayout, )}>
            <div className={styles.panelBody}>
                {children}
            </div>
        </div>
    )
};