import React from "react";
import {Provider} from "react-redux";
import store from "../../bootstrap3/app/store";
import {Route, Routes} from "react-router-dom";
import Boot from "../../bootstrap3/Pages/Boot";
import {Garanty} from "../../bootstrap3/Pages/Garanty";
import {Offer} from "../../bootstrap3/Pages/Offer";
import {ReturnInfo} from "../../bootstrap3/Pages/ReturnInfo";
import {Login} from "../../bootstrap3/Pages/Login";
import LottForm from "../../forms/LottForm";
import {default as BootstrapDonateForm} from "../../bootstrap3/DonateForm";
import {Helmet, HelmetProvider} from "react-helmet-async";
import favicon32 from "../../static/icons/favicon-32x32.png";
import favicon16 from "../../static/icons/favicon-16x16.png";
import {AppLayout} from "../../layouts/AppLayout";
import urls from "../../data/urls";

const Home = () => {
    window.location.replace(urls.front2.baseRoot);
    return null;
}

export const App = () => {
    return (
        <HelmetProvider>
            <Helmet>
                {/*<meta charSet="utf-8" />*/}
                <title>"Страница пожертвований"</title>
                <link rel="icon" type="image/png" sizes="32x32" href={favicon32}/>
                <link rel="icon" type="image/png" sizes="16x16" href={favicon16}/>
            </Helmet>
            <Provider store={store}>
                <span style={{"visibility": "hidden", "position": "absolute"}} className={"glyphicon glyphicon-user"}>gliphicon preloader</span>
                <Routes>
                    <Route exact path="/" element={<Boot/>}/>
                    <Route exact path="/donate" element={<AppLayout><BootstrapDonateForm/></AppLayout>}/>
                    <Route exact path="/garanty" element={<Garanty/>}/>
                    <Route exact path="/offer" element={<Offer/>}/>
                    <Route exact path="/return_policy" element={<ReturnInfo/>}/>
                    <Route exact path="/back/login" element={<Login/>}/>
                    <Route exact path="/lott" element={<LottForm/>}/>
                    <Route path={urls.front2.baseRoot} element={<Home/>}/>
                    <Route path="*" element={"Bootstrap route not match"}></Route>
                </Routes>
            </Provider>
        </HelmetProvider>
    );
};
