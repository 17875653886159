import React, {useEffect, useState} from "react";
import favicon32 from "../static/icons/favicon-32x32.png";
import favicon16 from "../static/icons/favicon-16x16.png";
import {MainNavBar} from "./MainNavBar";
import {NavBarBottom} from "./NavBarBottom";
import {Helmet, HelmetProvider} from 'react-helmet-async';
import {useSelector} from "react-redux";
import "./BootstrapLayout.module.scss";
import {AppLayout} from "../layouts/AppLayout";

export const BootstrapLayout = ({title, children}) => {
    const auth = useSelector((state) => state.auth);
    //https://dev.to/xjamundx/diy-pre-loading-for-faster-data-fetching-24ka

    return (
        <HelmetProvider>
            <Helmet>
                {/*<meta charSet="utf-8" />*/}
                <title>{title}</title>
                <link rel="icon" type="image/png" sizes="32x32" href={favicon32}/>
                <link rel="icon" type="image/png" sizes="16x16" href={favicon16}/>
            </Helmet>
            <AppLayout>
                <MainNavBar auth={auth}/>
                {/*<DonateForm/>*/}
                {children}
                <NavBarBottom/>
            </AppLayout>
        </HelmetProvider>
    )
};