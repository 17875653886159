import React, {useEffect} from "react";
import "./Boot.module.scss";
import DonateForm from "../DonateForm";
import {useSelector} from "react-redux";
import {BootstrapLayout} from "../BootstrapLayout";


const Boot = props => {
    // document.namedAdoptedStyleSheets["default"].disabled = true;
    const auth = useSelector((state) => state.auth);
    return (
        <BootstrapLayout title={"Страница пожертвований"}>
            <DonateForm/>
        </BootstrapLayout>
    )
};
export default Boot;