import React from "react";
import "./MainNavBar.module.scss";
import {Link} from "react-router-dom";
import urls from "../data/urls";

export function MainNavBar({auth}) {
    return <nav className="navbar navbar-default" styleName="navbar" role="navigation">
        <div className="container-fluid">
            <div className="navbar-header">
                <button type="button" className="navbar-toggle collapsed" data-toggle="collapse"
                        data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                    <span className="sr-only">Toggle navigation</span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                </button>
                <Link className="navbar-brand" to="/">Проекты</Link>
            </div>

            <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                <ul className="nav navbar-nav">
                    {
                        auth.isAuth &&
                        <>
                            {/*<li className="active">*/}
                            <li><a href="/back/my_orders">Мои заказы</a></li>
                            <li><a href="/back/my_subs">Мои подписки</a></li>
                        </>
                    }
                    {
                        auth.currentUser.role === 1 &&
                        <>
                            <li className="dropdown">
                                <a href="#" className="dropdown-toggle" data-toggle="dropdown" role="button"
                                   aria-expanded="false">
                                    <span className="glyphicon glyphicon-tasks"></span>
                                </a>
                                <ul className="dropdown-menu" role="menu">
                                    <li><a href="/back/orders"> Заказы</a></li>
                                    <li><Link to={urls.front2.baseRoot}>Home</Link></li>
                                    <li><a href="/back/projects"> Проекты </a></li>
                                    <li><a href="/back/subs"> Подписки </a></li>
                                    <li><a href="/back/pay_subs"> Подписки за период </a></li>
                                    <li><a href="/back/users"> Список пользователей </a></li>
                                    <li><a href="/back/stats"> Всего сборов </a></li>
                                    <li><a href="/back/stats_months">Сборы по месяцам </a></li>
                                    <li><a href="/back/sm">Сборы с фильтрами </a></li>
                                </ul>
                            </li>
                            <li className="dropdown">
                                <a href="#" className="dropdown-toggle" data-toggle="dropdown" role="button"
                                   aria-expanded="false"><span
                                    className="glyphicon glyphicon-cog"></span></a>
                                <ul className="dropdown-menu" role="menu">
                                    <li><a href="/create_all"> Создать все элементы базы (Create all)</a>
                                    </li>
                                    <li className="divider"></li>
                                    <li>
                                        {/*<a href="{{url_for('send_invite')}}"> Отправить приглашение </a>*/}
                                    </li>
                                </ul>
                            </li>
                        </>
                    }
                </ul>
                {auth.isAuth ?
                    <ul className="nav navbar-nav navbar-right">
                        <li className="dropdown">
                            <a href="#" className="dropdown-toggle" data-toggle="dropdown" role="button"
                               aria-expanded="false">
                                <b>{auth.currentUser.email}</b>
                                <span className="glyphicon glyphicon-user" styleName="glyphicon-gap"></span>
                            </a>
                            <ul className="dropdown-menu" role="menu">

                                <li><a href="/back/create_new_pass">Сменить пароль</a></li>
                                <li className="divider"></li>
                                <li><a href="/back/logout">Выйти</a></li>

                            </ul>
                        </li>
                    </ul>
                    : <>
                        <ul className="nav navbar-nav navbar-right">
                            <li><a href="/back/registration"><span
                                className="glyphicon glyphicon-user"></span> Регистрация</a></li>
                        </ul>
                        <ul className="nav navbar-nav navbar-right">
                            <li><a href="/back/login"><span
                                className="glyphicon glyphicon-log-in"></span> Вход</a></li>
                        </ul>
                    </>
                }

            </div>

        </div>

    </nav>;
}