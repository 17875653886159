import {createSlice} from '@reduxjs/toolkit'

const initialState = {isAuth: false, currentUser: {}}

const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        currentUserChanged(state, action) {
            // console.log(state.currentUser);
            // state.currentUser = action.payload;
            // state.isAuth = action.payload.is_auth;
            return {
                isAuth: action.payload.is_auth,
                currentUser: action.payload
            }
        },
    },
})

export const {currentUserChanged} = authSlice.actions

export default authSlice.reducer