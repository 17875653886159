import React, {useEffect} from "react";
import MainLayout from "../../features/Donate/MainLayout";
import "./Offer.module.scss"


export const Login = () => {
    useEffect(() => {
        return () => {
            document.bootstrap.disabled = false;
            console.log('Unmount');
        };
    }, []);

    document.bootstrap.disabled = true;
    // console.log(bootstrap_link_element);
    // elementsByTagName[0].disabled = true;
    // console.log(document.bootstrapcss);
    // document.bootstrapcss.disabled = true;
    return <MainLayout>Test</MainLayout>
}